// Override default variables before the import
$body-bg: ffff;
$primary: #f63275;
$secondary: #9ea0a5;
$dark: #3e3f42;
$light: #f6f7f9;
$dark-gray: #2f3036;
$white: #ffffff;

$theme-colors: (
  'primary': $primary,
  'dark': $dark,
  'secondary': $secondary,
  'light': $light,
  'gray': $dark-gray,
  'white': $white,
);

// Import Bootstrap and its default variables
@import 'bootstrap/scss/bootstrap.scss';
